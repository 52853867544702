import './MultiSelectDropdown.scss'
import React, { useState, useEffect } from 'react'

import Checkbox from '../Checkbox/Checkbox'

import { ReactComponent as ArrowDownIcon } from '../../icons/arrows-down-solid.svg'
import { ReactComponent as ArrowUpIcon } from '../../icons/arrows-up.svg'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { ReactComponent as Close2Icon } from '../../icons/close2.svg'

interface OPTION {
  label: string
  value: string
  disabled?: boolean
  count?: number
}

interface Props {
  name?: string
  options: OPTION[]
  value: string[]
  title?: string
  showMenu?: boolean
  counts?: any
  currentIndex?: number
  onChange: (selected: string[]) => void
  onDisabledClick?: (selected: any) => void
  selectAll?: boolean
}

export default function MultiSelectDropdown ({ value = [], counts = {}, options, title, selectAll = false, onChange, onDisabledClick, ...rest }: Props): React.ReactElement {
  const [showMenu, setShowMenu] = useState(true)
  const [search, setSearch] = useState('')
  const [doSeeLess] = useState(false)
  const [naics, setNaics] = useState<string[]>([])

  useEffect(() => {
    setNaics(value)
  }, [])

  const onSelectionChange = (option: OPTION): void => {
    const newValue = value.includes(option.value) ? value.filter(v => v !== option.value) : [...value, option.value]
    onChange(newValue)
  }

  const clearAll = (): void => {
    setNaics([])
    onChange([])
  }
  const selectAllOptions = (): void => {
    onChange([...options.map((i) => i.value)])
  }
  const getCount = (val: string): number => {
    if (rest.name === 'naics') {
      return val.split('-').map((v): number => counts[v] ?? 0).reduce((a, b) => a + b, 0)
    }
    return counts[val] ?? 0
  }

  const onSubmit = (e: any): void => {
    if (e.key === 'Enter') {
      setNaics([...naics, e.target.value])
      onChange([...naics, e.target.value])
      setSearch('')
    } else {
      setSearch(e.target?.value ?? '')
    }
  }

  return (
        <div className={'MultiSelectDropdown ' + ((showMenu ?? false) ? 'active' : '')}>

            {!showMenu && (<div className="MultiSelectDropdown__input" onClick={() => { setShowMenu(!(showMenu ?? false)) }}>
                <p className="filter-title">{title}</p>
                {!(showMenu ?? false) ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>)}
            <div className='collapse-menu'>
                {rest.currentIndex === 1 && (<a onClick={clearAll} className="clearSelect">Remove All</a>)}
                {rest.currentIndex !== 1 && (<a className='clearSelect' onClick={() => { selectAllOptions() }} >Select All</a>)}
                {rest.currentIndex !== 1 && (<a className='clearSelect' onClick={() => { clearAll() }} >Deselect All</a>)}

                {rest.currentIndex === 1 && (
                  <div className="dropdown-search">
                    <input type="text" value={search} placeholder="Search NAICS code" onChange={(e: any) => { setSearch(e.target?.value ?? '') }} onKeyDown={onSubmit} />
                    <SearchIcon />
                  </div>
                )}
                {rest.currentIndex !== 1 && (
                  <div className="dropdown-search">
                      <input type="text" placeholder="Search" onInput={(e: any) => { setSearch(e.target?.value ?? '') }} />
                      <SearchIcon />
                  </div>
                )}

                {rest.currentIndex === 1 && (
                  <div className='d-flex naics-wrapper'>
                    {naics?.map((item, index) => (
                      <div className='naics-item' key={index} onClick={() => {
                        setNaics(naics?.filter(ite => ite !== item))
                        onChange(naics?.filter(ite => ite !== item))
                      }}>{item} <Close2Icon /></div>
                    ))}
                  </div>
                )}
                 {rest.currentIndex !== 1 && (<ul>
                    {
                        options.filter(o => (search.length > 0) ? o.label.toLowerCase().includes(search.toLowerCase()) : true).map((option, index) =>

                          ((!doSeeLess || (doSeeLess && index < 5)) && <li key={option.value + ((option.disabled ?? false) ? '1' : '0')}>
                            <Checkbox fade={false} checked={value.includes(option.value)} label={option.label} onChange={() => { onSelectionChange(option) }} labelColor="#415473" />
                            {<span className="count">{getCount(option.value)}</span>}
                        </li>
                          ))
                    }
                </ul>)}
                {/* {options.length > 5 && doSeeLess && <p className='show-all' onClick={() => { setSeeLess(false) }} >Show All({options.length})</p>}
                {options.length > 5 && !doSeeLess && <p className='show-all' onClick={() => { setSeeLess(true) }} >Show Less</p>} */}

            </div>
        </div>
  )
}
