export enum RoutesEnum {
  Login = '/',
  SignUp = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  NewsList = '/news',
  NewsDetail = '/news/:type/:slug',
  RfpDetail = '/rfp/:id',
  Settings = '/settings',
  SetupPreference = '/setup-preference',
  TermsConditions = '/terms-conditions',
  PrivacyPolicy = '/privacy-policy',
  ConfirmAccount = '/confirm-account',
  VerifyMagicLink = '/verify-magic-link',
  MagicLinkSessionExpired = '/magic-link-expired',
  LoginMagicLink = '/login-magic-link'
}
