import './Login.scss'
import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
// import LoginContainer from '../../containers/LoginContainer1'

import Loader from '../../components/Loader/Loader'
import SvgList from '../../components/Animation/Animation'
import { ReactComponent as Logo1Icon } from '../../icons/logo1.svg'

import { useAppDispatch } from '../../hooks/useReduxToolkit'
import { loginUserMagicLink } from '../../core/store/user/userSlice'
import mixpanel from '../../utils/mixpanel'

export default function VerifyMagicLink (): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const verifyMagicLink = async (): Promise<void> => {
      const params = new URLSearchParams(location.search)
      const email = params.get('email')
      const session = params.get('session')
      const token = params.get('token')

      if (email != null && email.length > 0 && session != null && session.length > 0 && token != null && token.length > 0) {
        setIsLoading(true)
        await dispatch(loginUserMagicLink({
          email: email,
          token: token,
          session: session
        }))
        setIsLoading(false)
      } else {
        alert('Invalid magic link.')
      }
    }
    void verifyMagicLink()
    mixpanel.track('VerifyMagiclink')
  }, [dispatch, location])

  return (
    <>
    <div className="Header">
      <div className="Header__left h-100">
        <Link onClick={() => { }} to="/">
          <Logo1Icon style={{ height: 50 }} />
        </Link>
      </div>
    </div>
    <div className='login-anim-container'>

    {isLoading && <Loader />}
    <div className='anim-left d-flex flex-column align-items-center justify-content-center'>
    <SvgList />
    </div>
    {/* <LoginContainer> */}
    <div className="Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100 h-100 anim-right">

            {/* <div className="mb-4 Login__subtitle">Welcome! Let’s Get Started.</div> */}
     </div>
    {/* </LoginContainer> */}
    </div>
    </>
  )
}
